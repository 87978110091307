<template>
  <div class="loader">
    <AppIcon name="spinner" :size="size" v-bind="$attrs" />
  </div>
</template>

<script>
export default {
  name: 'SpinnerLoader',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: '18px',
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  :deep(.icon path) {
    fill: var(--color-black-04);
  }
}
</style>
