<template>
  <CustomScroll>
    <Transition name="show">
      <div
        v-if="isLoaded"
        class="payment-layout"
        :class="{
          'captcha-blur': isCaptchaVisible,
          'is-visible': isVisible,
        }"
      >
        <div class="payment-header--wrapper">
          <div id="payment-header" />
          <SelectLanguage type="black" class="select-language" />
        </div>
        <PaymentWrapper>
          <template #pic>
            <PaymentHeader />
          </template>
          <template #body>
            <router-view v-slot="{ Component }">
              <Transition name="show">
                <component :is="Component" @load="onLoad" />
              </Transition>
            </router-view>

            <SpinnerLoader v-if="isLoaderShow" />
          </template>
        </PaymentWrapper>
        <div id="payment-overlay" />
      </div>
    </Transition>
  </CustomScroll>
</template>

<script>
import { onMounted, ref } from 'vue';

import SpinnerLoader from '@/components/Loaders/SpinnerLoader.vue';
import SelectLanguage from '@/components/SelectLanguage';
import CustomScroll from '@/components/CustomScroll.vue';

import { paymentState } from '@/layouts/PaymentLayout/paymentState';

import PaymentWrapper from './PaymentWrapper.vue';
import PaymentHeader from './PaymentHeader.vue';

export default {
  name: 'PaymentLayout',
  components: {
    CustomScroll,
    PaymentWrapper,
    PaymentHeader,
    SpinnerLoader,
    SelectLanguage,
  },
  setup() {
    const isLoaded = ref(false);

    onMounted(() => { isLoaded.value = true; });

    const { isCaptchaVisible, isLoaderShow } = paymentState();

    const isVisible = ref(false);
    const onLoad = () => {
      setTimeout(() => {
        isVisible.value = true;
      }, 0);
    };

    return {
      isLoaded,
      isCaptchaVisible,
      isLoaderShow,

      isVisible,
      onLoad,
    };
  },
};
</script>

<style scoped lang="scss">
.payment-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #F7F7F7;
  position: relative;

  opacity: 0;
  @include transition-base(opacity);

  &.is-visible {
    opacity: 1;
  }

  &.captcha-blur {
    :deep(.picture-wrapper) {
      z-index: 3;
      &::before {
        border-radius: 50%;
        @include blurblock;
      }
    }

    :deep(.payment__card) {
      position: relative;

      &::before {
        border-radius: 20px;
        border: 1px solid var(--color-white-02);
        @include blurblock;
      }
    }

    :deep(.captcha) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      margin-top: 100px;
    }
  }
}

.payment-header--wrapper {
  position: absolute;
  top: 60px;
  width: 430px;
  margin: 0 auto;

  @include mq('mobile') {
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 410px;
    width: calc(100% - 60px);
    margin: 25px auto;
  }
  .select-language {
    display: none;
    @include mq('mobile') {
      display: block;
    }
  }
}
</style>
./PaymentWrapper.vue
