<template>
  <div class="footer">
    <div class="language-wrapper">
      <SelectLanguage is-short type="black" />
    </div>
    <div class="switch">
      <AppText :line-height="1.35" color="var(--color-black-04)" class="d-flex" style="gap: 10px">
        <i18n-t keypath="common.poweredBy">
          <template #switchLogo>
            <router-link to="/" :aria-label="t('ariaLabel.home')" class="logo-link">
              <AppIcon name="switch-logo-full" :opacity="0.3" size="74px" />
            </router-link>
          </template>
        </i18n-t>
      </AppText>
    </div>
  </div>
</template>

<script>
import SelectLanguage from '@/components/SelectLanguage';

export default {
  name: 'PaymentFooter',
  components: {
    SelectLanguage,
  },
};
</script>

<style scoped lang="scss">
:global(.select-language-popper) {
  @include mq('mobile') {
    margin-top: 10px;
  }
}
.footer {
  padding: 24px 12px;
  @include flex-center;
  max-width: calc(430px - 60px);
  margin: 20px auto;

  .language-wrapper {
    margin-right: 20px;
    :deep(.language-container) {
      margin-right: 0;
      width: unset;
      .f-dropdown--title {
        padding: 0;
      }

      &.language-container {
        width: unset;
      }
    }
  }

  @include mq('mobile') {
    padding-top: 22px;
    border-top: 1px solid var(--color-black-01);
    margin-top: -65px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: calc(100% - 80px);

    .language-wrapper {
      display: none;
    }
  }

  .switch {
    @include mq('mobile') {
      margin-left: 0;
    }

    :deep(.icon) {
      height: 12px;
      cursor: pointer;
      @include transition-base('opacity');

      &:hover {
        opacity: 1;
      }
    }
  }

  .logo-link {
    display: flex;
    align-items: center;
    height: 18px;
  }
}
</style>
