<template>
  <Transition name="show">
    <div v-if="isAvatarVisible" class="payment-header">
      <div class="picture-wrapper">
        <div class="picture">
          <AppIcon
            v-if="isIcon"
            :key="icon"
            :class="{ big: icon === 'user-default' }"
            :name="icon"
            size="28px"
            :opacity="icon === 'ban-block-slash' ? 0.3 : 1"
            is-img-tag
          />
          <FImage
            v-else-if="!isIcon && !isIoPlan"
            class="picture"
            alt="Avatar"
            :src="avatar"
          />

          <GlitchAvatar v-else :src="avatar" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { computed, defineComponent } from 'vue';

import GlitchAvatar from '@/components/Avatars/GlitchAvatar.vue';

import { paymentState } from '@/layouts/PaymentLayout/paymentState';

export default defineComponent({
  components: { GlitchAvatar },
  setup() {
    const {
      avatar, icon, isIcon, isAvatarVisible, merchantPlan,
    } = paymentState();

    const isIoPlan = computed(() => merchantPlan.value === 'IO');

    return {
      avatar,
      icon,
      isIcon,
      isAvatarVisible,
      isIoPlan,
    };
  },
});
</script>

<style scoped lang="scss">
.payment-header {
  position: relative;
  min-height: 45px;
}

.picture-wrapper {
  min-height: 45px;
  background: var(--color-white);
  position: absolute;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  top: -45px;
  left: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picture {
  background: #E5E5E5;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.big {
  width: 90px;
  height: 90px;
}
</style>
