<template>
  <div class="wrap" :class="{ 'add-shadow': isHelpOpened }">
    <Transition name="show">
      <slot name="header" />
    </Transition>

    <div ref="wrapper" class="payment__card">
      <slot name="pic" />
      <div class="wrapper__body">
        <div v-if="isLoaded" ref="wrapperContent" class="payment__body">
          <slot name="body" />
        </div>
      </div>
    </div>

    <Transition name="show">
      <PaymentFooter v-if="isFooterLoaded" />
    </Transition>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';

import { emitter } from '@/composables/useBus';

import PaymentFooter from './PaymentFooter.vue';

export default {
  name: 'PaymentWrapper',
  components: {
    PaymentFooter,
  },
  setup() {
    const isLoaded = ref(false);
    const isFooterLoaded = ref(false);

    const wrapper = ref(null);
    const wrapperContent = ref(null);

    const resizeObserver = new ResizeObserver((() => {
      let contentHeight = wrapperContent.value?.scrollHeight;
      if (contentHeight < 180) {
        return;
      }
      if (contentHeight > 180 && contentHeight < 390) {
        contentHeight = 390;
      }
      // if (contentHeight > 700) {
      //   contentHeight = 700;
      // }

      if (wrapper.value) {
        wrapper.value.style.setProperty('--contentHeight', `${contentHeight || '390'}px`);
      }
    }));

    const isHelpOpened = ref(false);

    emitter.on('help-opened', (val) => {
      isHelpOpened.value = val;
    });

    onMounted(() => {
      isLoaded.value = true;

      if (wrapper.value) {
        const initialHeight = wrapperContent.value?.scrollHeight;
        wrapper.value.style.setProperty('--contentHeight', `${initialHeight || '390'}px`);
      }

      setTimeout(() => { resizeObserver.observe(wrapperContent.value); }, 0);
      setTimeout(() => { isFooterLoaded.value = true; }, 0);
    });

    return {
      wrapper,
      wrapperContent,

      isLoaded,
      isFooterLoaded,
      isHelpOpened,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  margin-top: 100px;
  position: relative;
  @include transition-base;

  @include mq('mobile') {
    margin-top: 68px;
    width: 100%;
  }

  &.add-shadow {
    margin-top: 110px;
    @include transition-base(margin-top);

    @include mq('mobile') {
      margin-top: 60px;
    }

    .wrapper__body {
      box-shadow: 0 -10px 1px var(--color-black-003);
      @include transition-base;

      @include mq('mobile') {
        box-shadow: none;
      }
    }
  }

  .payment__card {
    width: 430px;
    background: var(--color-white);
    box-shadow: 0px 2px 6px 0px var(--color-black-01);
    border-radius: 20px;

    @include mq('mobile') {
      max-width: 430px;
      min-width: 340px;
      width: calc(100% - 40px);
      margin: 0 auto;
    }
  }
}

:deep(.el-button) {
  margin-bottom: 0;
}

.wrapper__body {
  min-height: 390px;
  border-radius: 20px;
  transition: all 0.1s cubic-bezier(0, 1.2, 1, 0.97);

  max-height: var(--contentHeight);
  min-height: var(--contentHeight);
}
</style>
