<template>
  <div class="glitch">
    <div class="glitch__img" />
    <div v-if="!isPause" class="glitch__img" />
    <div v-if="!isPause" class="glitch__img" />
    <div v-if="!isPause" class="glitch__img" />
    <div v-if="!isPause" class="glitch__img" />
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount, onMounted,
  ref,
  watch,
} from 'vue';
import { useIntervalFn } from '@vueuse/core';

import { useImage } from '@/composables/useImage';

export default {
  name: 'GlitchAvatar',
  props: {
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '80px',
    },
    isReadyPicture: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load', 'update'],
  setup(props, { emit }) {
    const {
      imageSrc,
      getImageUrl,
    } = useImage();

    onBeforeMount(() => {
      getAvatar();
    });

    const getAvatar = async () => {
      if (props.isReadyPicture) {
        imageSrc.value = props.src;
      } else {
        await getImageUrl(props.src);
      }
      emit('load');
    };

    watch(() => props.src, () => {
      getAvatar();
      emit('update', imageSrc.value);
    });

    const bgImg = computed(() => `url(${imageSrc.value})`);

    const isPause = ref(false);

    const runAnimationCycle = () => {
      useIntervalFn(() => {
        isPause.value = !isPause.value;
        setTimeout(() => {
          isPause.value = !isPause.value;
        }, 2100);
      }, 5500);
    };

    onMounted(() => {
      runAnimationCycle();
      setTimeout(() => {
        isPause.value = !isPause.value;
      }, 2100);
    });

    return {
      bgImg,
      isPause,
    };
  },
};
</script>

<style lang="scss" scoped>
.glitch {
  position: relative;
  width: v-bind('size');
  height: v-bind('size');
  overflow: hidden;
  margin: 0 auto;
  border-radius: 50%;
}

.glitch__img {
  position: absolute;
  top: 0;
  left: 0;
  width: v-bind('size');
  height: v-bind('size');
  background: v-bind('bgImg');
  background-color: transparent;
  background-size: cover;
  transform: translate3d(0,0,0);
  background-blend-mode: unset;
}

/* Set the background colors for the glitch images*/
.glitch__img:nth-child(2) {
  background-color: transparent;
  background-blend-mode: unset;
}

.glitch__img:nth-child(3) {
  background-color: transparent;
  background-blend-mode: unset;
}

.glitch__img:nth-child(4) {
  background-color: transparent;
  background-blend-mode: unset;
}

.glitch__img:nth-child(5) {
  background-color: transparent;
  background-blend-mode: unset;
}

/* Hide all images except the first one */
.glitch__img:nth-child(n+2) {
  opacity: 0;
}

/* On hover we show the 2nd, 3rd, 4th and 5th image*/
.glitch .glitch__img:nth-child(n+2) {
  opacity: 1;
}

/* Hover animations for horizontal case */
.glitch .glitch__img:nth-child(2) {
  transform: translate3d(4px,0,0);
  animation: glitch-anim-1-horizontal 2s infinite linear alternate;
}

.glitch > .glitch__img:nth-child(3) {
  transform: translate3d(-4px,0,0);
  animation: glitch-anim-2-horizontal 2s infinite linear alternate;
}

.glitch > .glitch__img:nth-child(4) {
  transform: translate3d(0, -2px, 0) scale3d(-1,-1,1);
  animation: glitch-anim-3-horizontal 2s infinite linear alternate;
}

/* Hover flash animation on last image */
.glitch > .glitch__img:nth-child(5) {
  animation: glitch-anim-flash 0.5s steps(1,end) infinite;
}

/* Animations */
@keyframes glitch-anim-1-horizontal {
  0% {
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }
  10% {
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }
  20% {
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  30% {
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  40% {
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }
  50% {
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  60% {
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  70% {
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  80% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  90% {
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  100% {
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
}

@keyframes glitch-anim-2-horizontal {
  0% {
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }
  15% {
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }
  22% {
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }
  31% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  45% {
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  51% {
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }
  63% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  76% {
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }
  81% {
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
  }
  94% {
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }
  100% {
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }
}

@keyframes glitch-anim-3-horizontal {
  0% {
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }
  5% {
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }
  10% {
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }
  25% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  27% {
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }
  30% {
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }
  33% {
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }
  37% {
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }
  40% {
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }
  45% {
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }
  50% {
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }
  53% {
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
  }
  57% {
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }
  60% {
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }
  65% {
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }
  70% {
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }
  73% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  80% {
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }
  100% {
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }
}

/* Flash */
@keyframes glitch-anim-flash {
  0% {
    opacity: 0.2;
    transform: translate3d(10%, 2px, 0);
  }
  33%, 100% {
    opacity: 0;
    transform: translate3d(0,0,0);
  }
}
</style>
